@tailwind base;
@tailwind components;
@tailwind utilities;



html,
body {
  height:100%;
  font-family: "Cinzel", serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*To make the nav full width*/
.b-transparent-shadow {
  width: 100%; 
  background-color: #121a20;
}   

/*For the Feature Section*/
.feature-section{ 
  border-top: solid 1px rgba(176,215,247,.18);
background:linear-gradient(#19242c,#121a20);
}

/*dark borders*/
.dark-borders{
  border:1px solid rgba(176,215,247,.18);
}

.sib-border{
  border:1px solid rgba(212, 190, 104,.70);
}


/*Background image for Quran*/
.quran-image{
  background:linear-gradient(0deg, rgba(25, 36, 44, 0.3), rgba(18, 26, 32, 0.9)), url("https://images.unsplash.com/photo-1609599006353-e629aaabfeae?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cXVyYW58ZW58MHx8MHx8fDA%3D");
  background-position: center;
  background-origin: border-box;
}

/*Background image for Salah*/
.salah-image{
  background:linear-gradient(0deg, rgba(25, 36, 44, 0.3), rgba(18, 26, 32, 0.7)), url("https://images.unsplash.com/photo-1664988519733-8019fd1fd22a?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjR8fHByYXllciUyMG1hdHxlbnwwfHwwfHx8MA%3D%3D");
  background-position: center;
  background-size:cover;
  background-origin: border-box;
}
/*Background Image for arabice*/
.arabic-image{
  background:linear-gradient(0deg, rgba(25, 36, 44, 0.3), rgba(18, 26, 32, 0.8)), url("https://images.unsplash.com/photo-1552423314-cf29ab68ad73?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8ZG9tZSUyMG9mJTIwdGhlJTIwcm9ja3xlbnwwfHwwfHx8MA%3D%3D");
  background-position: 46% 40%;
    background-size:cover;
    background-origin: border-box;
}

/*Background Image for heritage*/
.heritage-image{
  background:linear-gradient(0deg, rgba(25, 36, 44, 0.3), rgba(18, 26, 32, 0.9)), url("https://images.unsplash.com/photo-1510952267577-fc96d5ca660a?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXwxMTg2MzM0Nnx8ZW58MHx8fHx8");
  background-position: 65% 55%;
  background-size:cover;
    background-origin: border-box;
}

/*BGA color CTA*/
.bg-color-CTA{
  background:linear-gradient(0deg, rgba(18,26,32) , rgba(18, 26, 32, 0.6)), url("https://images.for9a.com/thumb/max-800-auto-80-avif/blog/30310-%D8%AA%D8%B9%D8%B1%D9%81-%D8%B9%D9%84%D9%89-%D8%A7%D9%94%D9%86%D9%88%D8%A7%D8%B9-%D8%A7%D9%84%D8%AE%D8%B7%D9%88%D8%B7-%D8%A7%D9%84%D8%B9%D8%B1%D8%A8%D9%8A%D8%A9-%D9%88%D8%A7%D9%94%D8%B4%D9%83%D8%A7%D9%84%D9%87%D8%A7-03.jpg");
  border: solid 1px rgba(176,215,247,.18);
}


.bg-color-box{
  background:linear-gradient(#19242c,#121a20);
  border: solid 1px rgba(176, 215, 247, 0.443);
}






